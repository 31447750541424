// TODO: post-migration: "button--secondary button--reverse" becomes "button--secondary".
// TODO: post-migration: "button--primary button--reverse" becomes "button--secondary".
// TODO: post-migration: remove button--reverse styles where possible.
// TODO: post-migration: button--tertiary becomes button--error.

/////////////////////////
// Button
/////////////////////////
.button {
  font-family: $font-primary;
  font-style: normal;
  text-decoration: none;
  cursor: pointer;

  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 0.5rem 0.75rem;

  border: none;
  outline: none;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: top;
  @include transition($transition);

  border-radius: 0.25rem;

  box-shadow: 0px 1px 2px 0px rgba(16, 40, 34, 0.05);

  &.disabled,
  &:disabled {
    background: var(--border-neutral-disabled);
    border-color: var(--border-neutral-disabled);
    color: var(--text-disabled);
    pointer-events: none;

    &:hover,
    &:focus {
      background: var(--border-neutral-disabled);
      border-color: var(--border-neutral-disabled);
    }
  }

  // Styles for outlined buttons (mostly unnecessary now)
  &.button--reverse {
    border-width: 1px;
    border-style: solid;
  }

  &.button--sml,
  &--sml {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }

  &.button--lg {
    padding: 0.625rem 0.85rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &.button--primary,
  &--primary {
    color: var(--text-white);
    background: var(--background-primary);

    &:hover {
      background: var(--background-primary-hover);
    }

    &:disabled,
    &.disabled {
      background: inherit;
      color: var(--text-disabled);
      border: solid 1px var(--border-primary-active);
    }

    // This is essentially button--secondary.
    &.button--reverse {
      background: inherit;
      color: var(--text-white);
      border: solid 1px var(--border-primary);

      &:hover {
        background: inherit;
        color: var(--text-primary-hover);
        border-color: var(--border-primary-active);
      }

      &:disabled {
        border-color: var(--border-primary-disabled);
        color: var(--text-disabled);
      }
    }
  }

  &.button--secondary,
  &--secondary {
    background: inherit;
    color: var(--text-white);
    border: solid 1px var(--border-primary);

    &:hover {
      background: inherit;
      color: var(--text-primary-hover);
      border-color: var(--border-primary-active);
    }

    &:disabled {
      border-color: var(--border-primary-disabled);
      color: var(--text-disabled);
    }

    // Removed button--reverse for Secondary style because secondary buttons are
    // now by definition outlined buttons. Use button--secondary-fill for filled buttons.
  }

  &.button--secondary-fill {
    background: var(--background-dark);
    color: var(--text-white);
    border: solid 1px var(--border-neutral);

    &:hover {
      background: var(--background-light);
    }

    &:disabled {
      background: var(--background-alt-disabled);
      color: var(--text-disabled);
    }
  }

  &.button--error,
  &--tertiary {
    background: var(--error-500);
    color: var(--text-white);

    &:hover {
      background: var(--error-600);
    }

    &:disabled {
      background: var(--background-alt-disabled);
      color: var(--text-disabled);
    }

    &.button--reverse {
      background: inherit;
      border-color: var(--error-500);
      color: var(--text-white);

      &:hover {
        background: var(-error-500);
      }

      &:disabled {
        border-color: var(--border-neutral-disabled);
        color: var(--text-disabled);
      }
    }
  }

  &.button--link {
    padding: 0.25rem;
    color: var(--text-primary);
    border: none;
    background: inherit;

    &.text-link {
      color: var(--text-weak);
      text-align: left;
    }

    &:hover:not(:disabled) {
      color: var(--text-primary-hover);
    }

    &.disabled,
    &:disabled {
      color: var(--text-disabled);
    }
  }

  // only used once
  &.button--upload input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    z-index: 1;
  }
}

body.user-is-tabbing {
  .button {
    &:focus {
      outline: none;
      box-shadow: 0 0 0 4px var(--border-primary-focus);
      border-radius: 0.25rem;
    }
  }

  .button--secondary-fill {
    &:focus {
      box-shadow: 0 0 0 4px var(--neutral-300);
    }
  }

  .button--error,
  .button--tertiary {
    &:focus {
      box-shadow: 0 0 0 4px var(--error-100);
    }
  }
}

/////////////////////////
// Button Group
/////////////////////////

.button-group {
  display: flex;
  align-items: center;

  .button:not(:first-child),
  button:not(:first-child) {
    margin-left: 1rem;
  }

  &.switcher,
  &.toggle {
    a:first-child,
    .button:first-child,
    button:first-child {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-right-width: 1px;
    }

    a:last-child,
    .button:last-child,
    button:last-child {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-left-width: 1px;
    }

    a:not(:first-child):not(:last-child),
    .button:not(:first-child):not(:last-child),
    button:not(:first-child):not(:last-child) {
      border-radius: 0;
      border-left-width: 1px;
      border-right-width: 1px;
    }

    .button:not(:first-child),
    button:not(:first-child) {
      margin-left: 0;
    }

    a:hover,
    .button:hover,
    button:hover {
      background: none;
      border-color: var(--border-primary-active);
      color: var(--text-primary-hover);
    }

    a.active,
    .button.active,
    button.active {
      background: var(--border-primary-active);
      border-color: var(--border-primary-active);
      color: var(--text-white);
    }
  }

  // conceptually the same as a toggle above but with some extra stuff
  &.switcher {
    flex-wrap: wrap;

    @include media-breakpoint-down(md) {
      &.flex-column {
        a,
        button,
        .button {
          width: 100%;
        }
      }
    }

    a,
    button,
    .button {
      background: inherit;
      border: solid 1px var(--border-primary-active);
      color: var(--text-white);
      flex-grow: 1;

      &:hover {
        color: var(--text-primary-hover);
      }

      &:focus {
        z-index: 2;
      }

      &.disabled,
      &:disabled {
        color: var(--text-disabled);
      }
    }

    a.active,
    button.active,
    .button.active {
      color: var(--text-white);
      background: var(--border-primary-active);
      border-color: var(--border-primary-active);

      &:hover {
        background: var(--background-primary);
      }

      &.disabled,
      &:disabled {
        color: var(--text-white);
        background: var(--background-primary-disabled);
      }
    }

    &.sml {
      a,
      button,
      .button {
        @include font__size(12px);
        @include line__height(20px);
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }

    @include media-breakpoint-down(md) {
      & {
        border-bottom: solid 2px var(--border-primary);
        flex-wrap: wrap;
        position: relative;
        justify-content: center;

        &:after {
          content: '';
          height: 2px;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -2px;
          box-shadow: 0px 0px 11px 9px rgba(0, 0, 0, 0.1);
        }

        a,
        button,
        .button {
          box-shadow: none;
          outline: none;
          border: none;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          vertical-align: top;
          border-radius: 0;
          color: var(--text-white);
          @include transition($transition);
          width: 100%;
          padding: 0.5rem 0.5rem;
          font-size: 0.6875rem;
          background: var(--neutral-700);
        }
      }
    }
  }
}

/////////////////////////
// Extra Button Styles
/////////////////////////

// TODO: only used once in NotebookDetails.js and could just be done with an Accordion or scoped to that page.
.button-toggle {
  display: flex;
  align-items: center;

  i {
    @include transition($transition);
  }

  &.show {
    i {
      @include rotate(180deg);
    }
  }

  @include media-breakpoint-up(xl) {
    & {
      display: none !important;
    }
  }
}

/** TODO: combine with button styles more cleanly (i.e. primary, secondary, error) */
.button--icon {
  background: transparent;
  border: solid 1px var(--neutral-400);
  border-radius: 0.25rem;
  width: 34px;
  height: 34px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: top;
  padding: 0;
  color: var(--text-white);
  font-size: 1.125rem;
  line-height: 1.5rem;
  @include transition($transition);
  text-decoration: none;
  cursor: pointer;

  svg,
  img {
    height: 1rem;
    width: auto;
    cursor: pointer;
  }

  .icon-button-sidebar-expanded {
    margin-left: 400px !important;
  }

  &.button--lg,
  &.lrg {
    width: 3rem;
    height: 3rem;
    @include font__size(20px);

    img {
      height: 1.25rem;
    }
  }

  &.button--sml,
  &.sml {
    width: 1.5rem;
    height: 1.5rem;
    @include font__size(12px);

    img {
      height: 0.75rem;
    }
  }

  &:hover {
    border-color: var(--border-primary-focus);
    background: none;
  }

  &.primary {
    background: var(--background-primary);
    color: var(--text-white);
    border-color: var(--background-primary);

    &:hover {
      background: transparent;
      color: var(--text-white);
    }
  }

  &.tertiary {
    background: var(--error-500);
    color: var(--text-white);
    border-color: var(--error-500);

    &:hover {
      background: var(--error-600);
    }
  }

  &:disabled {
    background: var(--background-alt-disabled);
    border-color: var(--border-neutral-disabled);
    color: var(--text-disabled);

    &:hover {
      background: var(--background-alt-disabled);
      border-color: var(--border-neutral-disabled);
      color: var(--text-disabled);
    }
  }
}

body.user-is-tabbing {
  .button--icon {
    &:focus {
      outline: none;
      box-shadow: 0 0 0 4px var(--border-primary-focus);
    }
  }
}
