.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;

  &:hover {
    background-color: var(--neutral-900);
    cursor: pointer;
  }

  .item-text {
    display: flex;
    align-items: center;
    align-content: center;
  }
}

.neo-radio {
  border-radius: 0.625rem;
  border: 1px solid var(--border-primary);
  background: var(--background-inverse);
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
  &:hover {
    cursor: pointer;
  }
  &:checked {
    color: var(--brand-primary);
    border: 1px solid var(--brand-primary);
    background: var(--brand-primary);
    &:after {
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

.short-search {
  min-width: 0;
}

.airport-item {
  border: 1px outset var(--neutral-700);
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin-bottom: 0rem;
  &:hover {
    background-color: var(--neutral-700);
    border-radius: 0.25rem;
    cursor: pointer;
  }

  // Prevent highlighting text on clicks
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.list-border {
  height: 50vh;
  overflow-y: auto;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 1rem;
  &.small {
    height: calc(25vh - 20px);
  }
}

.port-list {
  width: 50%;
  padding-left: 3px;
  padding-right: 3px;
}

.selected-item {
  background-color: var(--background-light);
  border: 1px solid var(--brand-primary);
  border-radius: 0.25rem;
  &:hover {
    background-color: var(--neutral-600);
  }
}

.neo-dropdown-item {
  padding: 0.5rem;
  margin-bottom: 0rem;
  &.selected {
    background-color: var(--background-light);
    border: 1px solid var(--brand-primary);
    border-radius: 0.25rem;
  }
  &:hover {
    background-color: var(--neutral-700);
    cursor: pointer;
  }
}

.neo-filter-button {
  &:hover {
    color: var(--text-white) !important;
  }
}
